import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { getColour } from "../../styles/shared/colour";

export const ICON_SIZES = {
  Default: 16,
  Medium: 20,
  Large: 32,
};
export enum ICON_ID {
  AccountCircle = 1,
  SwapHorizontal = 2,
  Logout = 3,
  ChevronRight = 4,
  ReceiptLong = 5,
  Domain = 6,
  CalenderClock = 7,
  ArrowBack = 8,
  Search = 9,
  ExpandDown = 10,
  ExpandUp = 11,
  AddCircle = 12,
  Close = 13,
  Add = 14,
  Remove = 15,
  ShoppingBasket = 16,
  CheckCircle = 17,
  Error = 18,
  Notification = 19,
  Edit = 20,
  Delete = 21,
  Save = 22,
  Store = 23,
  Info = 24,
  Approval = 25,
  Inventory = 26,
  Groups = 27,
  QuestionMark = 28,
  Upload = 29,
  CloudUpload = 30,
  Password = 31,
  Check = 32,
  Category = 33,
  ManageAccounts = 34,
  Widgets = 35,
}

export const Icons: Record<ICON_ID, string> = {
  [ICON_ID.AccountCircle]: "account_circle",
  [ICON_ID.SwapHorizontal]: "swap_horiz",
  [ICON_ID.Logout]: "logout",
  [ICON_ID.ChevronRight]: "chevron_right",
  [ICON_ID.ReceiptLong]: "receipt_long",
  [ICON_ID.Domain]: "domain",
  [ICON_ID.CalenderClock]: "calendar_clock",
  [ICON_ID.ArrowBack]: "arrow_back",
  [ICON_ID.Search]: "search",
  [ICON_ID.ExpandDown]: "expand_circle_down",
  [ICON_ID.ExpandUp]: "expand_circle_up",
  [ICON_ID.AddCircle]: "add_circle",
  [ICON_ID.Close]: "close",
  [ICON_ID.Add]: "add",
  [ICON_ID.Remove]: "remove",
  [ICON_ID.ShoppingBasket]: "shopping_cart",
  [ICON_ID.CheckCircle]: "check_circle",
  [ICON_ID.Error]: "error",
  [ICON_ID.Notification]: "notifications",
  [ICON_ID.Edit]: "edit",
  [ICON_ID.Delete]: "delete",
  [ICON_ID.Save]: "save",
  [ICON_ID.Store]: "store",
  [ICON_ID.Info]: "info",
  [ICON_ID.Approval]: "approval",
  [ICON_ID.Inventory]: "inventory_2",
  [ICON_ID.Groups]: "groups",
  [ICON_ID.QuestionMark]: "question_mark",
  [ICON_ID.Upload]: "upload",
  [ICON_ID.CloudUpload]: "cloud_upload",
  [ICON_ID.Password]: "password",
  [ICON_ID.Check]: "check",
  [ICON_ID.Category]: "category",
  [ICON_ID.ManageAccounts]: "manage_accounts",
  [ICON_ID.Widgets]: "widgets",
};

interface GoogleIconProps {
  id: ICON_ID;
  size?: number;
  isDanger?: boolean | false;
  isSuccess?: boolean | false;
  isWarning?: boolean | false;
  isContrast?: boolean | false;
  isSecondary?: boolean | false;
  isNeutral?: boolean | false;
  isPrimary?: boolean | true;
  tooltip?: string;
}

export const GoogleIcon: React.FC<GoogleIconProps> = ({
  id,
  size = 16,
  isDanger = false,
  isSuccess = false,
  isWarning = false,
  isContrast = false,
  isPrimary = true,
  isSecondary = false,
  isNeutral = false,
  tooltip = "",
}) => {
  const iconName = Icons[id] || null;
  const color = getColour(
    isDanger,
    isSuccess,
    isWarning,
    isContrast,
    isPrimary,
    isSecondary,
    isNeutral
  );

  return (
    <StyledIcon
      title={tooltip}
      size={size ?? ICON_SIZES.Default}
      color={color}
      className="material-symbols-outlined"
    >
      {iconName}
    </StyledIcon>
  );
};

interface StyledIconProps {
  size: number;
  color: keyof DefaultTheme["colors"];
}

const StyledIcon = styled.span<StyledIconProps>`
  font-size: ${(p) => p.size + "px"};
  color: ${({ theme, color }) =>
    theme.application.scheme[color] || theme.application.scheme.primary};
  vertical-align: middle;
  margin-right: 11px;
`;
