import React from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import Header from "../../components/layout/header";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import Back from "../../components/layout/back";
import {
  ActiveArea,
  Area,
  ContentArea,
  HeaderArea,
  Main,
  Title,
  TitleArea,
  VerticalContent,
} from "../../styles/shared/page";
import ModalHandler from "../modals/modal-handler";
import Toastr from "../../components/notification/toastr";
import { useErrorDisplay } from "../../hooks/useErrorDisplay";
import ConfirmModal from "../../components/notification/confirm-modal";
import DrawerHandler from "../drawer/drawer-handler";

interface LayoutProps {
  children: React.ReactNode;
}

const OwnerLayout: React.FC<LayoutProps> = ({ children }) => {
  const user = useAppSelector((state) => state.user);
  useErrorDisplay();

  return (
    <div className="layout">
      <Toastr></Toastr>
      <ModalHandler></ModalHandler>
      <ConfirmModal></ConfirmModal>
      <DrawerHandler></DrawerHandler>
      <Header name={user?.user?.name} team={user?.user?.role} />
      <VerticalContent>
        <HeaderArea>
          <Back></Back>
          <TitleArea>
            <Title>Owner Portal
              {user.currentPage && (
                <Area> / {user.currentPage}</Area>
              )}
            </Title>

          </TitleArea>
        </HeaderArea>
        <Main>
          <ContentArea>
            <ActiveArea>{children}</ActiveArea>
          </ContentArea>
        </Main>
      </VerticalContent>
    </div>
  );
};

export default OwnerLayout;
