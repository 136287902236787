import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ApplicationProductList } from "../../../models/product/application-product-list";
import { ApplicationError } from "../../../models/errors/application-error";
import AdminProductService from "../../../services/product/admin-product-service";
import { ApplicationCategory } from "../../../models/product/application-category";
import ProductCategoryService from "../../../services/product/product-category-service";
import { ApplicationDepartment } from "../../../models/organisation/application-department";
import DepartmentService from "../../../services/organisation/department-service";
import { ApplicationProductDetail } from "../../../models/product/application-product-detail";
import { ApplicationProduct } from "../../../models/product/application-product";
export const fetchProductsByCategory = createAsyncThunk<
  ApplicationProductList | null,
  { categoryId: number; search: boolean },
  { state: RootState }
>(
  "manageProduct/fetchByCategory",
  async ({ categoryId, search }, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const adminProductService = new AdminProductService();

    try {

      var currentPosition: number =
        state.adminProduct.products?.currentPosition ?? 0;
      var fetchNext: number = 15;
      var count: number = state.adminProduct.products?.count ?? -1;

      if (search) {
        currentPosition = 0;
        count = -1;
        dispatch(resetProducts());
      }

      if (currentPosition !== count) {
        return await adminProductService.getProductsByCategory(
          categoryId,
          currentPosition,
          fetchNext,
          state.adminProduct.searchText
        );
      }

      return null;
    } catch (error: any) {
      const apiError = ApplicationError.handleApiError(error, {});
      return rejectWithValue(apiError);
    }
  }
);

export const fetchDepartmentById = createAsyncThunk<
  ApplicationDepartment,
  number,
  { state: RootState }
>("departments/fetchById", async (departmentId, { rejectWithValue }) => {
  const departmentService = new DepartmentService();
  try {
    return await departmentService.getDepartmentById(departmentId);
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});

export const fetchProductById = createAsyncThunk<
  ApplicationProductDetail | null,
  number,
  { state: RootState }
>("manageProduct/getSingle", async (productId, { rejectWithValue }) => {
  const adminProductService = new AdminProductService();

  try {
    return await adminProductService.getProductById(productId);
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});
export const updateManageProduct = createAsyncThunk<
  ApplicationProduct | null,
  ApplicationProductDetail,
  { state: RootState }
>("manageProduct/update", async (product, { rejectWithValue }) => {
  const adminProductService = new AdminProductService();

  try {
    return await adminProductService.updateManageProduct(product);
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});

export const insertManageProduct = createAsyncThunk<
  ApplicationProduct | null,
  ApplicationProductDetail,
  { state: RootState }
>("manageProduct/insert", async (product, { rejectWithValue }) => {
  const adminProductService = new AdminProductService();

  try {
    return await adminProductService.insertManageProduct(product);
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});

export const checkDuplicateCategoryName = createAsyncThunk<
  boolean,
  { categoryId: number; name: string },
  { state: RootState }
>(
  "product/checkDuplicateCategoryName",
  async ({ categoryId, name }, { rejectWithValue }) => {
    const adminProductService = new AdminProductService();

    try {
      return await adminProductService.checkDuplicateCategoryName(
        categoryId,
        name
      );
    } catch (error: any) {
      const apiError = ApplicationError.handleApiError(error, {});
      return rejectWithValue(apiError);
    }
  }
);

interface AdminProductState {
  products: ApplicationProductList | null;
  selectedProduct: ApplicationProductDetail | null;
  searchText: string;
}

const initialState: AdminProductState = {
  products: null,
  selectedProduct: null,
  searchText: "",
};

const adminProductSlice = createSlice({
  name: "adminProduct",
  initialState,
  reducers: {
    resetProducts: (state) => {
      state.products = initialState.products;
    },
    resetSelectedProduct: (state) => {
      state.selectedProduct = initialState.selectedProduct;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchProductsByCategory.fulfilled,
        (state, action: PayloadAction<ApplicationProductList | null>) => {
          if (action.payload) {
            if (state.products?.products) {
              state.products.products = state.products.products.concat(
                action.payload.products
              );
              state.products.currentPosition = action.payload.currentPosition;
              state.products.count = action.payload.count;
            } else {
              state.products = action.payload;
            }
          }
        }
      )
      .addCase(
        fetchProductById.fulfilled,
        (state, action: PayloadAction<ApplicationProductDetail | null>) => {
          if (action.payload) {
            state.selectedProduct = action.payload;
          }
        }
      )
      .addCase(
        updateManageProduct.fulfilled,
        (state, action: PayloadAction<ApplicationProduct | null>) => {
          if (action.payload && state.products?.products) {
            state.products.products = state.products.products.map((product) =>
              product.id === action.payload!.id
                ? (action.payload as ApplicationProduct)
                : product
            );
          }
        }
      )
      .addCase(
        insertManageProduct.fulfilled,
        (state, action: PayloadAction<ApplicationProduct | null>) => {
          if (action.payload && state.products?.products) {
            state.products.products.unshift(action.payload);
            state.products.count += 1;
            state.products.currentPosition += 1;
          }
        }
      );
  },
});

export const { resetProducts, resetSelectedProduct, setSearch } =
  adminProductSlice.actions;
export default adminProductSlice.reducer;
