import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import Drawer from "@mui/material/Drawer";
import { closeDrawer } from "../../store/features/drawer/drawer-slice";
import { ICON_ID } from "../../components/icons/google-icon";
import Button from "../../components/controls/button";
import styled from "styled-components";
import { useDrawer } from "../../hooks/useDrawer";

export default function DrawerHandler() {
  const dispatch = useAppDispatch();
  const { component: DrawerContent, data } = useDrawer();
  const { anchor, open } = useAppSelector((state) => state.drawer);

  const handleClose = () => {
    dispatch(closeDrawer());
  };

  if (!DrawerContent) {
    return null;
  }
  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          margin: {
            xs: 0,
            sm: "16px",
          },
          borderRadius: {
            xs: 0,
            sm: "6px",
          },
          width: {
            xs: "100%",
            sm: "70%",
            md: "40%",
            lg: anchor === "top" || anchor === "bottom" ? "auto" : "25vw",
          },
          height: {
            xs: "100%",
            sm: "calc(100vh - 40px)",
          },
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          overflowY: "overlay"
        },
      }}
    >
      <StyledHeaderContainer>
        <StyledHeader>{data?.headerText || "Default Header"}</StyledHeader>
        <Button
          onClick={handleClose}
          label=""
          variant="text"
          icon={ICON_ID.Close}
          id="btnCloseDrawer"
        />
      </StyledHeaderContainer>
      <DrawerContent {...data} />
    </Drawer>
  );
}

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0 16px 16px;
`;

const StyledHeader = styled.h2`
  margin: 0;
  font-size: 1.0625rem;
  letter-spacing: -0.02em;
`;
