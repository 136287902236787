import { useEffect } from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import styled from "styled-components";
import TableView, { ColumnConfig } from "../../components/controls/table-view";
import Button from "../../components/controls/button";
import { TextContainer } from "../../styles/shared/table";
import { openDrawer } from "../../store/features/drawer/drawer-slice";
import { DrawerID } from "../../constants/drawer-constants";
import {
  GoogleIcon,
  ICON_SIZES,
  ICON_ID,
} from "../../components/icons/google-icon";
import useScreenSize from "../../hooks/useScreenSize";
import { useParams } from "react-router-dom";
import {
  fetchProductsByCategory,
  resetSelectedProduct,
  fetchProductById,
  fetchDepartmentById,
  setSearch,
} from "../../store/features/product/admin-product-slice";
import {
  ProductContainer,
  ProductDescription,
  ProductImage,
  ProductNameContainer,
  ProductTitle,
} from "../../styles/shared/product";
import { ApplicationCategory } from "../../models/product/application-category";
import Image from "../../components/controls/image";
import { fetchBranches } from "../../store/features/organisation/branch-slice";
import { ApplicationDepartment } from "../../models/organisation/application-department";
import { fetchCategory, setSelectedDepartment } from "../../store/features/product/product-category-slice";
import { setCurrentPage } from "../../store/features/authentication/user-slice";
export default function AdminMasterProductView() {
  const { departmentId, categoryId } = useParams();
  const { isMobile } = useScreenSize();
  const productState = useAppSelector((state) => state.adminProduct);
  const productCategoryState = useAppSelector((state) => state.productCategory);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      categoryId &&
      productCategoryState.selectedCategory == null &&
      departmentId &&
      productCategoryState.selectedDepartment == null
    ) {
      dispatch(fetchCategory(parseInt(categoryId))).then((categoryResponse) => {
        const category = categoryResponse.payload as ApplicationCategory;
        if (category) {
          dispatch(fetchDepartmentById(parseInt(departmentId))).then(
            (departmentResponse) => {
              dispatch(
                setSelectedDepartment(
                  departmentResponse.payload as ApplicationDepartment
                )
              );
            }
          );
        }
      });
    }
  }, [
    categoryId,
    departmentId,
    productCategoryState.selectedCategory,
    productCategoryState.selectedDepartment,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(fetchBranches());
    dispatch(setCurrentPage("Manage Products"))

    return () => {
      dispatch(setSearch(""));
    };
  }, [dispatch]);

  const fetchMoreData = async (search: boolean) => {
    if (
      productCategoryState.selectedCategory != null &&
      productCategoryState.selectedDepartment != null
    ) {
      dispatch(fetchProductsByCategory({
        categoryId: productCategoryState.selectedCategory.id,
        search,
      }));
    }
  };

  const renderActionButton = (row: any) => (
    <Button
      variant={isMobile ? "text" : "outlined"}
      id={row.Name}
      label={isMobile ? "" : "Edit"}
      icon={isMobile ? ICON_ID.Edit : undefined}
      isPrimary={true}
      fullWidth={false}
      onClick={() => EditProduct(row)}
    />
  );

  const openCreateNewProduct = () => {
    dispatch(resetSelectedProduct());
    dispatch(
      openDrawer({
        id: DrawerID.AdminProduct,
        anchor: "right",
        data: {
          headerText: "Create New Product",
        },
      })
    );
  };
  const EditProduct = async (row: any) => {
    const response = await dispatch(fetchProductById(row.id));
    if (response) {
      dispatch(
        openDrawer({
          id: DrawerID.AdminProduct,
          anchor: "right",
          data: {
            headerText: `Edit ${row.name}`,
          },
        })
      );
    }
  };
  const columnConfig: { [key: string]: ColumnConfig } = {
    id: {
      hidden: true,
    },
    image: {
      hidden: true,
    },
    categoryId: {
      hidden: true,
    },
    categoryName: {
      order: 3,
      hidden: categoryId != null,
      name: "Category",
      render: (value: string) => (
        <TextContainer>{value}</TextContainer>
      )
    },
    plu: {
      order: 2,
    },
    name: {
      order: 1,
      name: "Product",
      colspan: 2,
      render: (value: string, row: any) => (
        <ProductContainer>
          <ProductImage>
            {row.image ? (
              <Image image={row.image}></Image>
            ) : (
              <></>
            )}
          </ProductImage>
          <ProductNameContainer>
            <ProductTitle>
              {value}

            </ProductTitle>
            <ProductDescription>
              {row.description}
            </ProductDescription>
          </ProductNameContainer>
        </ProductContainer>
      ),
    },

    active: {
      hideOnMobile: true,
      order: 2,
      render: (value: boolean) => (
        <TextContainer>
          {value ? (
            <GoogleIcon
              tooltip="Active"
              id={ICON_ID.CheckCircle}
              size={ICON_SIZES.Large}
              isSuccess
            />
          ) : (
            <GoogleIcon
              tooltip="Disabled"
              id={ICON_ID.Close}
              size={ICON_SIZES.Large}
              isDanger
            />
          )}
        </TextContainer>
      ),
    },
  };

  const renderToolbar = () => (
    <Button
      id="createNewProduct"
      onClick={openCreateNewProduct}
      label="New Product"
      variant="contained"
      icon={ICON_ID.Add}
    ></Button>
  )
  return (
    <Container>
      <ContentSection>
        <ContentTitle>
          <FieldsGridContainer>
            <ProductDepartmentTitle>
              {productCategoryState.selectedDepartment?.name || ""}
            </ProductDepartmentTitle>
            <DepartmentCategoryTitle>
              {productCategoryState.selectedCategory?.name || ""}
            </DepartmentCategoryTitle>
          </FieldsGridContainer>
        </ContentTitle>
        <Body>
          <TableContainer>
            <TableView
              id="tblProducts"
              emptyText="No products"
              toolbar={renderToolbar}
              fetchMoreData={(search) => fetchMoreData(search ?? false)}
              totalCount={productState.products?.count ?? 0}
              data={productState?.products?.products ?? []}
              columnConfig={columnConfig}
              actionButton={renderActionButton}
              showHeaders={true}
              setSearchText={(searchText) => dispatch(setSearch(searchText))}
              searchText={productState.searchText}
            />
          </TableContainer>
        </Body>
      </ContentSection>
    </Container>
  );
}
const Body = styled.div`
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  margin: 10px;
  column-gap: 20px;
  height: 100%;
  @media (max-width: 1600px) {
    flex-direction: column;
  }
`;

const ProductDepartmentTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
`;
const DepartmentCategoryTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${(props) => props.theme.application.scheme.primary};
`;
const ContentTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  border-bottom: 2px solid ${(props) => props.theme.application.scheme.primary};
  padding: 5px 0px 5px 5px;
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  background: ${(props) => props.theme.application.background};
  border-radius: 4px;
  height: 100%;
`;
const FieldsGridContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const TableContainer = styled.div`
  padding: 5px;
  height: 95%;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
