import { ApplicationError } from "../../models/errors/application-error";
import { ApplicationProductList } from "../../models/product/application-product-list";
import { ApplicationProduct } from "../../models/product/application-product";
import client from "../../external/open-api";
import IAdminProductService from "./admin-product-service-interface";
import { components } from "../../external/schema/order-link-api-schema";
import { ApplicationProductDetail } from "../../models/product/application-product-detail";
import { mapStringToEnum } from "../../helpers/enum-helper";
import { ApplicationSizeUnit } from "../../models/product/application-size-unit";
import { ApplicationBranch } from "../../models/organisation/application-branch";
export default class AdminProductService implements IAdminProductService {
  async getProductsByCategory(
    categoryId: number,
    currentPosition: number,
    fetchNext: number,
    searchText: string
  ): Promise<ApplicationProductList> {
    const { data, error } = await client.GET(
      `/v{version}/api/manageproduct/category/{categoryId}`,
      {
        params: {
          path: { version: "1", categoryId },
          query: {
            "Paging.CurrentPosition": currentPosition,
            "Paging.FetchNext": fetchNext,
            "Paging.FetchAll": false,
            SearchText: searchText,
          },
        },
      }
    );
    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to fetch products");
    }
    return {
      categoryId: categoryId,
      products: this.mapToApplicationProduct(data.data),
      loading: false,
      count: data.data?.count || 0,
      currentPosition: data.data?.currentPosition || 0,
    };
  }
  async getProductById(productId: number): Promise<ApplicationProductDetail> {
    const { data, error } = await client.GET(
      "/v{version}/api/manageproduct/{productId}",
      {
        params: {
          path: { version: "1", productId },
        },
      }
    );

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to get product");
    }

    return this.mapToProductDetail(data.data);
  }

  async insertManageProduct(
    product: ApplicationProductDetail
  ): Promise<ApplicationProduct> {
    const productDto = this.mapToProductDTO(product);
    const { data, error } = await client.POST("/v{version}/api/manageproduct", {
      params: {
        path: { version: "1" },
      },
      body: {
        ...productDto,
      },
    });
    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to update Product");
    }

    return data.data as ApplicationProduct;
  }

  async updateManageProduct(
    product: ApplicationProductDetail
  ): Promise<ApplicationProduct> {
    const productDto = this.mapToProductDTO(product);
    const { data, error } = await client.PUT("/v{version}/api/manageproduct", {
      params: {
        path: { version: "1" },
      },
      body: {
        ...productDto,
      },
    });
    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to update Product");
    }

    return data.data as ApplicationProduct;
  }

  async checkDuplicateCategoryName(
    categoryId: number,
    name: string
  ): Promise<boolean> {
    const { data, error } = await client.GET(
      "/v{version}/api/manageproduct/category/{categoryId}/search",
      {
        params: {
          path: { version: "1", categoryId },
          query: { name },
        },
      }
    );

    if (error || !data?.success) {
      throw new ApplicationError("Failed to check duplicate category name");
    }

    return Boolean(data.data);
  }

  private mapToApplicationProduct(
    data: components["schemas"]["GetProductsResponse"] | undefined
  ): ApplicationProduct[] {
    if (!data) {
      return [];
    }

    return (
      data.products?.map((d) => ({
        id: d.id ?? 0,
        categoryId: d.categoryId ?? 0,
        categoryName: d.categoryName ?? "",
        name: d.name ?? "",
        image: d.image ?? "",
        plu: d.plu ?? "",
        active: d.active ?? false,
      })) || []
    );
  }
  private mapToProductDetail(
    data: components["schemas"]["ProductDetailDTO"]
  ): ApplicationProductDetail {
    return {
      id: data.id ?? 0,
      name: data.name ?? "",
      description: data.description ?? "",
      categoryId: data.categoryId ?? 0,
      image: data.image ?? "",
      plu: data.plu ?? "",
      active: data.active ?? false,
      information: data.productInformation ?? {},
      suggestedQuantity: data.suggestedQuantity ?? 0,
      supplierSizeUnit: data.supplierSizeUnit
        ? mapStringToEnum(ApplicationSizeUnit, data.supplierSizeUnit)
        : undefined,
      branches:
        data.branches?.map((branch: components["schemas"]["BranchDTO"]) => ({
          id: branch.id ?? 0,
          name: branch.name ?? "",
        })) ?? [],
    };
  }

  private mapToProductDTO(
    product: ApplicationProductDetail
  ): components["schemas"]["ProductDetailDTO"] {
    return {
      id: product.id,
      name: product.name,
      description: product.description,
      categoryId: product.categoryId,
      image: product.image,
      plu: product.plu,
      active: product.active,
      productInformation: product.information,
      suggestedQuantity: product.suggestedQuantity,
      supplierSizeUnit: product.supplierSizeUnit,
      branches: product.branches.map((branch: ApplicationBranch) => ({
        id: branch.id,
        name: branch.name,
      })),
    };
  }
}
