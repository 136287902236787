import ActionRequestModal from "../pages/modals/action-request-modal";
import ContinousProductModal from "../pages/modals/continous-product-modal";
import ProductModal from "../pages/modals/product-modal";
import UploadSupplierProductModal from "../pages/modals/upload-supplier-product-modal";
export enum ModalID {
  Product = 1,
  ContinousProduct = 2,
  ActionRequest = 3,
  UploadSupplierProduct = 4,
}

export const ModalComponents: Record<ModalID, React.ComponentType<any>> = {
  [ModalID.Product]: ProductModal,
  [ModalID.ContinousProduct]: ContinousProductModal,
  [ModalID.ActionRequest]: ActionRequestModal,
  [ModalID.UploadSupplierProduct]: UploadSupplierProductModal,
};
