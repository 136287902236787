import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { keyframes, useTheme } from "styled-components";
import { ScrollableCard } from "../../styles/shared/card";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import { blurAnimation } from "../../styles/shared/loading";
import LoadingSpinner from "./loading-spinner";
import { TextContainer } from "../../styles/shared/table";
import TextInput from "./text-input";
import { ICON_ID } from "../icons/google-icon";
import { eventNames } from "process";

export interface ColumnConfig {
  label?: string;
  hidden?: boolean;
  name?: string;
  render?: (value: any, row: { [key: string]: any }) => React.ReactNode;
  order?: number;
  align?: string | "center" | "left" | "right";
  colspan?: number;
  hideOnMobile?: boolean
}

interface TableViewProps {
  id: string;
  columnConfig?: { [key: string]: ColumnConfig };
  actionButton?: (row: { [key: string]: any }) => React.ReactNode;
  toolbar?: () => React.ReactNode;
  fetchMoreData?: (search?: boolean) => Promise<void>;
  data: { [key: string]: any }[];
  totalCount: number;
  preLoadedData?: boolean;
  emptyText: string;
  onRowClick?: (row: { [key: string]: any }) => void;
  showHeaders?: boolean;
  searchText?: string;
  setSearchText?: (value: string) => void;
}

export default function TableView({
  id,
  columnConfig = {},
  actionButton,
  toolbar,
  fetchMoreData,
  data,
  totalCount,
  emptyText,
  preLoadedData = false,
  showHeaders = false,
  searchText,
  setSearchText,
  onRowClick,
}: TableViewProps) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const rowRef = useRef<HTMLDivElement>(null);
  const loadingRowRef = useRef<HTMLDivElement>(null);
  const [displayData, setDisplayData] = useState(data);

  useInfiniteScroll({
    dataLength: displayData.length,
    totalCount: totalCount,
    fetchMoreData: fetchMoreData || (async () => { }),
    setLoading,
    loading,
    loadingRowRef
  });

  useEffect(() => {
    setDisplayData(data);
  }, [data]);

  useEffect(() => {
    if (data.length === 0 && !preLoadedData && fetchMoreData && !loading && !searchText) {
      setPageLoading(true);
      fetchMoreData(false).then(() => {
        setPageLoading(false);
      })
    }
  }, [data, fetchMoreData, loading, searchText]);

  useEffect(() => {
    return () => {
      if (setSearchText) {
        setSearchText("");
      }
    }
  }, [])


  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const searchData = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchLoading(true);
    setLoading(true);
    if (setSearchText && fetchMoreData) {
      setSearchText(event.target.value);
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }

      searchTimeoutRef.current = setTimeout(() => {
        fetchMoreData(true).then(() => {
          setSearchLoading(false);
          setLoading(false);
        })

      }, 2000);
    }
  };



  if (pageLoading) {
    return (
      <ScrollableCard
        background={theme.application.table.background}
        scrollbar={theme.application.scrollBar}
      >
        <NoContent>
          <LoadingSpinner></LoadingSpinner>
        </NoContent>
      </ScrollableCard>
    );
  }

  const getFilteredColumns = (
    columns: string[],
    configPredicate: (column: string) => boolean
  ): string[] => {
    return columns.filter(configPredicate);
  };

  const calculateColspans = (
    columns: string[],
    columnConfig: { [key: string]: ColumnConfig }
  ): { sumOfColspans: number; numberOfColspanColumns: number } => {
    return columns.reduce(
      (acc, column) => {
        const colspan = columnConfig[column]?.colspan || 1;
        if (colspan > 1) {
          acc.sumOfColspans += colspan;
          acc.numberOfColspanColumns++;
        }
        return acc;
      },
      { sumOfColspans: 0, numberOfColspanColumns: 0 }
    );
  };

  const calculateColumnLength = (
    columns: string[],
    columnConfig: { [key: string]: ColumnConfig },
    actionButton: boolean
  ): number => {
    const { sumOfColspans, numberOfColspanColumns } = calculateColspans(columns, columnConfig);
    return columns.length + sumOfColspans - numberOfColspanColumns + (actionButton ? 1 : 0);
  };

  const deriveColumns = (
    data: any[],
    columnConfig: { [key: string]: ColumnConfig }
  ): string[] => {
    return data.length > 0
      ? Object.keys(data[0] ?? []).filter((column) => !columnConfig[column]?.hidden)
      : [];
  };

  const columns: string[] = deriveColumns(data, columnConfig);

  const sortedColumns: string[] = columns.sort((a, b) => {
    const orderA = columnConfig[a]?.order ?? Number.MAX_SAFE_INTEGER;
    const orderB = columnConfig[b]?.order ?? Number.MAX_SAFE_INTEGER;
    return orderA - orderB;
  });

  const columnLength: number = calculateColumnLength(sortedColumns, columnConfig, actionButton != null);

  const mobileColumns: string[] = getFilteredColumns(
    sortedColumns,
    (column) => columnConfig[column]?.hideOnMobile !== true
  );

  const mobileColumnLength: number = calculateColumnLength(mobileColumns, columnConfig, actionButton != null);


  return (
    <>
      {(setSearchText || toolbar) && (
        <Toolbar>
          {setSearchText && (
            <SearchWrapper>
              <TextInput id="txtSearch" label="Search" value={searchText ?? ""} onChange={(event) => searchData(event)} type="text" iconID={ICON_ID.Search} ></TextInput>
            </SearchWrapper>
          )}
          {toolbar && (
            <div>{toolbar()}</div>
          )}
          {loading && (
            <LoadingSpinner></LoadingSpinner>
          )}

        </Toolbar>
      )}


      <TableWrapper id={id} $toolbar={setSearchText != null}>
        <ScrollableCard
          background={theme.application.table.background}
          scrollbar={theme.application.scrollBar}
        >
          {showHeaders && (
            <TableHeader>
              <HeaderRow $columnCount={columnLength} $mobileColumnCount={mobileColumnLength}>
                {sortedColumns.map((header) => {
                  const config = columnConfig[header];
                  return (
                    <TableCell
                      $header={true}
                      $colSpan={config?.colspan}
                      $hideOnMobile={config?.hideOnMobile ?? false}
                      key={`header_${header}`}
                      $align={config?.align ?? "left"}
                    >
                      {config?.name ?? header}{" "}
                    </TableCell>
                  );
                })}
              </HeaderRow>
            </TableHeader>

          )}

          {searchLoading ? (
            <TableBody>
              <LoadingRow>
                {sortedColumns.map((_column, index) => (
                  <LoadingCell key={`load${index}`}></LoadingCell>
                ))}
                <LoadingCell></LoadingCell>
              </LoadingRow>
            </TableBody>

          ) : (

            (displayData.length === 0 && !searchLoading && !loading) ? (
              <NoContent>
                <TextContainer>{emptyText}</TextContainer>
              </NoContent>
            ) : (


              <TableBody>
                {displayData.map((row) => (
                  <TableRow
                    $mobileColumnCount={mobileColumnLength}
                    $columnCount={columnLength}
                    onClick={() => (onRowClick ? onRowClick(row) : null)}
                    ref={rowRef}
                    key={`dataRow-${row.id}`}
                  >
                    {sortedColumns.map((column) => (
                      <TableCell
                        $hideOnMobile={columnConfig[column]?.hideOnMobile ?? false}
                        $colSpan={columnConfig[column]?.colspan}
                        $align={columnConfig[column]?.align ?? "left"}
                        key={column}
                      >
                        {columnConfig[column]?.render
                          ? columnConfig[column].render!(row[column], row)
                          : row[column]}
                      </TableCell>
                    ))}
                    {actionButton && (
                      <TableCell $hideOnMobile={false} $align="center">{actionButton(row)}</TableCell>
                    )}
                  </TableRow>
                ))}

                {totalCount != displayData.length && (
                  <LoadingRow ref={loadingRowRef}>
                    {sortedColumns.map((_column, index) => (
                      <LoadingCell key={`load${index}`}></LoadingCell>
                    ))}
                    <LoadingCell></LoadingCell>
                  </LoadingRow>
                )}
              </TableBody>
            )
          )}
        </ScrollableCard>
      </TableWrapper>

    </>

  );
}

interface TableWrapperProps {
  $toolbar: boolean;
}


const TableWrapper = styled.div<TableWrapperProps>`
    display: grid;
    height:  ${(props) => props.$toolbar ? "90%" : "100%"}
`;

const TableBody = styled.div`
  display: grid;


`;

const TableHeader = styled.div`
  display: grid;
  position: sticky;
  top: 0;
  z-index: 2;
  color: ${(props) => props.theme.application.scheme.contrast};
`;

const SearchWrapper = styled.div`  
min-width: 30%;

`

const Toolbar = styled.div`  
display:flex;
width:100%;
flex-direction:row;
background:rgba(255, 255, 255, 0.5);
padding-left:5px; 
align-items: center;
gap:10px;
overflox-x:

`
interface TableRowProps {
  $columnCount: number;
  $mobileColumnCount: number;
}

const HeaderRow = styled.div<TableRowProps>`
  display: grid;    
  background: ${(props) => props.theme.application.scheme.primary};
  grid-template-columns: repeat(
    ${(props) => props.$columnCount},
    minmax(100px, 1fr)
  );
  cursor: pointer;
  align-items: center;

 @media (max-width: 600px) {
    grid-template-columns: repeat(${(props) => props.$mobileColumnCount}, minmax(100px, 1fr));
  }
  

`;

const TableRow = styled.div<TableRowProps>`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => props.$columnCount},
    minmax(100px, 1fr)
  );
  border-bottom: 1px solid ${(props) => props.theme.application.border};
  cursor: pointer;
  align-items: center;
  &:hover {
    background: ${(props) => props.theme.application.table.hover};
  }

 @media (max-width: 600px) {
    grid-template-columns: repeat(${(props) => props.$mobileColumnCount}, minmax(100px, 1fr));
  }
  
`;

interface TableCellProps {
  $align: string;
  $colSpan?: number;
  $header?: boolean;
  $hideOnMobile: boolean;
}
const TableCell = styled.div<TableCellProps>`
  padding: 10px;
  text-align: ${(props) => (props.$align ?? "center")};
  grid-column: ${(props) =>
    props.$colSpan ? `span ${props.$colSpan}` : "auto"};
  text-transform: ${(props) => (props.$header ? "capitalize" : "")};
  font-weight: ${(props) => (props.$header ? "600" : "")};

    @media (max-width: 600px) {
      display: ${(props) => (props.$hideOnMobile ? "none" : "block")}; 
  }

  background: ${(props) => props.theme.application.table.cellBackground};
`;

const LoadingRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
  padding: 10px 0;
  animation: ${blurAnimation} 0.5s infinite;
  height: 50px;
`;

const LoadingCell = styled.div`
  padding: 20px;
  border-radius: 15px;
  padding: 20px;
  border-radius: 6px;
  background: ${(props) => props.theme.application.placeholder};
  margin: 0px 16px;
`;

const NoContent = styled.div`
  text-align: center;
  margin-top: 21%;
`;
