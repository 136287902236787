import { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "../../components/controls/button";
import TextInput from "../../components/controls/text-input";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { closeDrawer } from "../../store/features/drawer/drawer-slice";
import {
  GoogleIcon,
  ICON_ID,
  ICON_SIZES,
} from "../../components/icons/google-icon";
import { requiredValidator } from "../../helpers/validators";
import {
  FeatureCard,
  FeatureCardTitle,
  FeatureCardColumn,
} from "../../styles/shared/card";
import { MainContent, Footer, ButtonGroup } from "../../styles/shared/drawer";
import { showSuccess } from "../../components/notification/toastr-actions";
import Checkbox from "../../components/controls/checkbox";
import Image from "../../components/controls/image";
import { ApplicationProductDetail } from "../../models/product/application-product-detail";
import { ApplicationBranch } from "../../models/organisation/application-branch";
import {
  updateManageProduct,
  insertManageProduct,
  checkDuplicateCategoryName,
} from "../../store/features/product/admin-product-slice";
import { ApplicationSizeUnit } from "../../models/product/application-size-unit";
import { useNavigate } from "react-router-dom";

interface Validation {
  name: string | null;
  description: string | null;
  plu: string | null;
}
interface DynamicFieldValidation {
  dynamicFields: DynamicFieldErrors;
}

interface DynamicField {
  key: string | null;
  value: string | null;
}

interface DynamicFieldErrors {
  [index: number]: {
    key: string | null;
    value: string | null;
  };
}

interface AdminProductDrawerProps {
  departmentId?: number | null,
  categoryId?: number | null
}

export default function AdminProductDrawer({ departmentId, categoryId }: AdminProductDrawerProps) {
  const dispatch = useAppDispatch();
  const selectedProduct = useAppSelector(
    (state) => state.adminProduct
  ).selectedProduct;
  const productState = useAppSelector((state) => state.adminProduct);
  const branches = useAppSelector((state) => state.branch).branches;
  const [dynamicFields, setDynamicFields] = useState<DynamicField[]>([]);
  const navigate = useNavigate();
  const [validation, setValidation] = useState<Validation>({
    name: null,
    description: null,
    plu: null,
  });

  const [dynamicFieldValidation, setDynamicFieldValidation] =
    useState<DynamicFieldValidation>({
      dynamicFields: {},
    });
  const defaultProductDetail: ApplicationProductDetail = {
    id: 0,
    name: "",
    categoryId: categoryId ?? (productState.products?.categoryId ?? 0),
    description: "",
    image: "",
    plu: "",
    active: true,
    information: {},
    suggestedQuantity: 0,
    supplierSizeUnit: ApplicationSizeUnit.Unknown,
    branches: [],
  };
  const [product, setProduct] = useState<ApplicationProductDetail>(
    selectedProduct || defaultProductDetail
  );
  useEffect(() => {
    if (branches && product.id === 0) {
      setProduct((prev) => ({
        ...prev,
        branches: [...branches],
      }));
    }
  }, [branches]);

  const onChange = <K extends keyof ApplicationProductDetail>(
    value: ApplicationProductDetail[K],
    property: K,
    validation?: (input: ApplicationProductDetail[K]) => string | null
  ) => {
    const errorMessage = validation ? validation(value) : null;

    setProduct((prevState) => ({
      ...prevState,
      [property]: value,
    }));

    setValidation((prevState) => ({
      ...prevState,
      [property]: errorMessage,
    }));
  };

  const onUpdate = async () => {
    if (!validateAndCheckErrors()) {
      return;
    }
    if (!dynamicFieldValidateAndCheckErrors()) {
      return;
    }
    if (product.id === 0 && product.name) {
      const nameExists = await dispatch(
        checkDuplicateCategoryName({
          categoryId: productState.products?.categoryId ?? 0,
          name: product.name,
        })
      );
      if (nameExists.payload) {
        setValidation((prev) => ({
          ...prev,
          name: "Name already exists",
        }));
        return;
      }
    }
    const updatedProduct = {
      ...product,
      information: dynamicFields.reduce(
        (acc, field) => {
          if (field.key && field.value) {
            acc[field.key] = field.value;
          }
          return acc;
        },
        { ...(product.information || {}) }
      ),
    };

    let notificationMessage = `${product.name} `;
    if (product.id == 0) {
      await dispatch(insertManageProduct(updatedProduct));
      notificationMessage += "created";
    } else {
      await dispatch(updateManageProduct(updatedProduct));
      notificationMessage += "updated";
    }
    dispatch(showSuccess(notificationMessage));
    dispatch(closeDrawer());

    if (departmentId && categoryId) {
      navigate(`/admin/${departmentId}/category/${categoryId}/products`);
    }
  };
  const validateAndCheckErrors = () => {
    const validation = {
      name: requiredValidator(product.name),
      description: requiredValidator(product.description),
      plu: requiredValidator(product.plu),
    };
    setValidation(validation);
    const hasErrors = Object.values(validation).some((error) => error !== null);
    if (hasErrors) {
      return false;
    } else {
      return true;
    }
  };

  const dynamicFieldValidateAndCheckErrors = () => {
    const validation = {
      dynamicFields: validateDynamicFields(dynamicFields),
    };
    setDynamicFieldValidation(validation);

    const hasErrors = Object.values(validation.dynamicFields).some(
      (fieldErrors) => fieldErrors.key !== null || fieldErrors.value !== null
    );
    if (hasErrors) {
      return false;
    } else {
      return true;
    }
  };

  const validateDynamicFields = (
    dynamicFields: DynamicField[]
  ): DynamicFieldErrors => {
    const errors: DynamicFieldErrors = {};

    dynamicFields.forEach((field, index) => {
      const keyError = requiredValidator(field.key);
      const valueError = requiredValidator(field.value);
      if (keyError || valueError) {
        errors[index] = {
          key: keyError,
          value: valueError,
        };
      }
    });

    return errors;
  };

  const toggleBranchSelection = (branch: ApplicationBranch) => {
    const isSelected = product.branches.some((b) => b.id === branch.id);
    setProduct((prev) => ({
      ...prev,
      branches: isSelected
        ? prev.branches.filter((b) => b.id !== branch.id)
        : [...prev.branches, branch],
    }));
  };
  const handleAddField = () => {
    if (!dynamicFieldValidateAndCheckErrors()) {
      return;
    }
    setDynamicFields([...dynamicFields, { key: "", value: "" }]);
  };

  const handleRemoveField = (index: number) => {
    setDynamicFields((prevFields) => prevFields.filter((_, i) => i !== index));
  };

  const handleFieldChange = <K extends keyof DynamicField>(
    value: DynamicField[K],
    index: number,
    property: K,
    validation?: (input: DynamicField[K]) => string | null
  ) => {
    const updatedFields = dynamicFields.map((item, i) =>
      i === index ? { ...item, [property]: value } : item
    );
    const errorMessage = validation ? validation(value) : null;
    setDynamicFieldValidation((prev) => ({
      ...prev,
      dynamicFields: {
        ...prev.dynamicFields,
        [index]: {
          key: prev.dynamicFields[index]?.key ?? null,
          value: prev.dynamicFields[index]?.value ?? null,
          [property]: errorMessage,
        },
      },
    }));

    setDynamicFields(updatedFields);
  };
  const handleRemoveInfo = (key: any) => {
    setProduct((prevProduct) => {
      const newInformation = { ...prevProduct.information };
      delete newInformation[key];
      return {
        ...prevProduct,
        information: newInformation,
      };
    });
  };
  return (
    <>
      <MainContent>
        <Container>
          <CheckboxContainer>
            <Checkbox
              label="Active"
              checked={product.active}
              onChange={(event) => onChange(event.target.checked, "active")}
            ></Checkbox>
          </CheckboxContainer>
          <ImageContainer>
            <ProductImage>
              {product.image ? (
                <Image image={product.image}></Image>
              ) : (
                <NoImageBox>No Image</NoImageBox>
              )}
            </ProductImage>
            <Button
              variant="text"
              label={product.image ? "Change Picture" : "Add Picture"}
              id="btnPicture"
              isPrimary={true}
              fullWidth={false}
            />
          </ImageContainer>
          <TextInput
            id="txtName"
            label="Name"
            value={product.name}
            error={validation.name}
            onChange={(event) =>
              onChange(event.target.value, "name", (input) =>
                requiredValidator(input)
              )
            }
          />
          <TextInput
            id="txtDescription"
            value={product.description}
            label="Description"
            onChange={(event) =>
              onChange(event.target.value, "description", (input) =>
                requiredValidator(input)
              )
            }
            error={validation.description}
          />
          <TextInput
            id="txtPlu"
            value={product.plu}
            label="Plu"
            onChange={(event) =>
              onChange(event.target.value, "plu", (input) =>
                requiredValidator(input)
              )
            }
            error={validation.plu}
          />
          <FeatureCard>
            <FeatureCardTitle>Product Information</FeatureCardTitle>
            <InfoContainer>
              {Object.entries(product.information).map(([key, value]) => (
                <InfoRow key={key}>
                  <BoldInfoField>{key}</BoldInfoField>
                  <InfoField>{value}</InfoField>
                  <InfoField onClick={() => handleRemoveInfo(key)}>
                    <GoogleIcon
                      isDanger={true}
                      id={ICON_ID.Close}
                      size={ICON_SIZES.Medium}
                    ></GoogleIcon>
                  </InfoField>
                </InfoRow>
              ))}
            </InfoContainer>
            {dynamicFields.map((field, index) => (
              <FieldWrapper key={index}>
                <TextInputWrapper>
                  <TextInput
                    id={`txtKey${index}`}
                    label="Key"
                    value={field.key ?? ""}
                    onChange={(e) =>
                      handleFieldChange(e.target.value, index, "key", (input) =>
                        requiredValidator(input)
                      )
                    }
                    error={dynamicFieldValidation.dynamicFields?.[index]?.key}
                  />
                </TextInputWrapper>
                <TextInputWrapper>
                  <TextInput
                    id={`txtSize${index}`}
                    label="value"
                    value={field.value ?? ""}
                    onChange={(e) =>
                      handleFieldChange(
                        e.target.value,
                        index,
                        "value",
                        (input) => requiredValidator(input)
                      )
                    }
                    error={dynamicFieldValidation.dynamicFields?.[index]?.value}
                  />
                </TextInputWrapper>

                <Button
                  variant="text"
                  label=""
                  id="btnCancel"
                  icon={ICON_ID.Close}
                  onClick={() => handleRemoveField(index)}
                />
              </FieldWrapper>
            ))}
            <ButtonWrapper>
              <Button
                label="Add"
                variant="text"
                id="BtnAdd"
                icon={ICON_ID.Add}
                onClick={handleAddField}
              />
            </ButtonWrapper>
          </FeatureCard>
          <FeatureCard>
            <FeatureCardTitle>Branches</FeatureCardTitle>
            <FeatureCardRow>
              {branches?.map((branch) => (
                <FeatureCardColumn key={branch.id}>
                  <BranchItem onClick={() => toggleBranchSelection(branch)}>
                    {branch.name}
                    {product.branches?.some((b) => b.id === branch.id) ? (
                      <GoogleIcon
                        isSuccess={true}
                        id={ICON_ID.Check}
                        size={ICON_SIZES.Medium}
                      ></GoogleIcon>
                    ) : (
                      <GoogleIcon
                        isDanger={true}
                        id={ICON_ID.Close}
                        size={ICON_SIZES.Medium}
                      ></GoogleIcon>
                    )}
                  </BranchItem>
                </FeatureCardColumn>
              ))}
            </FeatureCardRow>
          </FeatureCard>
        </Container>
      </MainContent>
      <Footer>
        <ButtonGroup>
          <Button
            label="Cancel"
            variant="outlined"
            id="btnCancelDrawer"
            onClick={() => dispatch(closeDrawer())}
          />
          <Button
            id="btnCreate"
            label={product.id == 0 ? "Create" : "Update"}
            variant="contained"
            onClick={onUpdate}
          />
        </ButtonGroup>
      </Footer>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const ProductImage = styled.div`
  width: 100px;
  @media (min-width: 760px) {
    width: 120px;
  }
  margin-bottom: 10px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const BranchItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px;
  &:hover {
    background-color: ${({ theme }) => theme.application.hover};
  }
`;
const NoImageBox = styled.div`
  width: 100px;
  height: 100px;
  background-color: ${({ theme }) =>
    theme.application.product.productBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: ${({ theme }) => theme.application.text};
  font-size: 14px;
  text-align: center;
  @media (min-width: 760px) {
    width: 120px;
    height: 120px;
  }
`;
const FeatureCardRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
`;

const InfoRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  border: 1px solid ${(props) => props.theme.application.border};
  padding: 10px;
  border-radius: 4px;
  gap: 20px;
  margin-bottom: 10px;
`;

const InfoField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  cursor: pointer;
`;
const BoldInfoField = styled(InfoField)`
  font-weight: bold;
  text-transform: capitalize;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const FieldWrapper = styled.div`
  display: flex;
  margin-left: 10px;
`;

const TextInputWrapper = styled.div`
  flex: 1;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;
